// Other imports remain unchanged
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import cn from 'classnames';
import { serverTimestamp, setDoc } from 'firebase/firestore';
import SEO from '@components/seo';
import imageUrlFor from '@helpers/imageUrlFor';
import withFirebase from '@src/account/Firebase/withFirebase.js';
import Button from '@src/components/Button';
import FeaturesModule from '@src/components/FeaturesModule';
import HeroPrimary from '@src/components/HeroPrimary';
import RichTextContentBox from '@src/components/RichTextContentBox/index.js';
import { bytesToMB } from '@src/helpers/bytesToMB.js';
import FormError from '@src/utility/FormError';
import FormHoneypot from '@src/utility/FormHoneypot';
import FormInput from '@src/utility/FormInput';
import FormSelect from '@src/utility/FormSelect';
import FormUpload from '@src/utility/FormUpload/index.js';
import Link from '@src/utility/Link';
import Spinner from '../../static/images/loader-circle.gif';
import * as skuStyles from './skuStyles';
import {
  CREATE_ACCOUNT_USER_ROLES_TRADE,
  EMAIL_ERROR_MESSAGE,
  EMAIL_FIELD_PATTERN,
  FIREBASE_ERRORS,
  REQUIRED_FIELD,
} from '../../common.js';
import { signUpSuccessDL } from '@src/helpers/dataLayerHelper';

const getFileErrorMessage = (errorType) => {
  switch (errorType) {
    case 'type':
      return 'Only PDF/JPG/JPEG/PNG formats are accepted';
    case 'maxFiles':
      return 'You can upload up to 5 files';
    case 'size':
      return 'Each file should not exceed 10MB';
    case 'required':
      return REQUIRED_FIELD;
    default:
      return '';
  }
};

const allowedTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'application/pdf',
];

const TradePageTemplate = ({
  pageContext: {
    title,
    description,
    metaImage,
    _rawHero,
    _rawFirstContentModule,
    secondContentModule,
  },
  firebase,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const { errors } = formState;

  const [submitError, setSubmitError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUStradeCompany, setIsUStradeCompany] = useState(false);
  const [showUSTradeError, setShowUSTradeError] = useState(false);

  const formRef = useRef();

  const errorHandler = (error) => {
    setSubmitError({...error, message: FIREBASE_ERRORS[error.code] ?? error.message});
    setIsSubmitting(false);
    window.scrollTo({
      top: formRef.current?.offsetTop || 0,
      behavior: 'smooth',
    });
  };

  const createAttachments = (urls, documentTypes) => {
    let attachments = [];
    urls.map((itemUrl, index) => {
      let attachment = {
        filename: `File-${index + 1}`,
        path: itemUrl,
        contentType: documentTypes[index],
        contentDisposition: 'attachment',
      };
      attachments.push(attachment);
    });

    return attachments;
  };

  const onSubmit = async (data) => {
    if (!isUStradeCompany) {
      setShowUSTradeError(true);
      return;
    }

    setShowUSTradeError(false);
    setIsSubmitting(true);

    const documents = [...data.documents];

    try {
      const newAuthUser = await firebase.firebaseCreateUserWithEmailAndPassword(
        data.email,
        data.passwordOne,
      );

      await firebase.auth.currentUser?.getIdToken(true);
      await firebase.auth.currentUser?.reload();

      const urls = await Promise.all(
        documents.map((doc) => {
          return firebase.firebaseUploadTradeDocsFile(
            doc,
            `${newAuthUser.user.uid}-${doc.name}`,
          );
        }),
      );

      try {
        const signupData = {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          company: data.company,
          company_address: data.companyAddress,
          user_type: decodeURI(data.userRole),
          user_trade: false,
          created: serverTimestamp(),
          documents: urls,
          company_url: data.companyUrl,
          phone: data.phone,
          product_note: data.productNote
        };
        await setDoc(firebase.user(newAuthUser.user.uid), signupData, {
          merge: true,
        });

        firebase
          .sendMailOnTradeSignUp({
            ...signupData,
            name: data.firstName + ' ' + data.lastName,
            attachments: createAttachments(
              urls,
              documents.map((e) => e.type),
            ),
          })
          .then((res) => console.log('Email sent on trade signup'));
      } catch (err) {
        let userData = {
          email: data.email,
          name: data.firstName + ' ' + data.lastName,
          id: newAuthUser.user.uid,
        };
        firebase.sendMailOnUserCreationFailure(userData).then((res) => {
          console.log('Email sent. Error:', err);
        });
      }

      await firebase.sendVerficationCodeToMail({ email: data.email });

      localStorage.setItem('hasCreatedRBWAccount', true);
      firebase.firebaseSignOut();
      signUpSuccessDL(data.email, 'Firebase Trade')

      return navigate('/account/verify-inbox', {
        state: {
          email: data.email,
          uuid: newAuthUser.user.uid,
          password: data.passwordOne,
        },
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="mb-10">
      <SEO
        title={title}
        description={description}
        image={metaImage?.asset ? imageUrlFor(metaImage).url() : null}
      />
      {_rawHero && <HeroPrimary data={_rawHero} halfHeight />}
      <div className="wrapper">
        <div className="row md:flex-row flex-col-reverse">
          <div
            className="min-h-screen col-12 md:col-4 md:offset-1 xl:col-4"
            ref={formRef}
          >
            <h2 className="type-sans-830 xl:type-sans-930 mb-8">Apply Now</h2>
            <p className="type-sans-330 text-mono-500 mb-8 pr-6">
              You can quote with ease, apply trade pricing or organize specs by
              project with a RBW Trade account.
            </p>
            {submitError && submitError.message && (
              <FormError>
                <p dangerouslySetInnerHTML={{__html: submitError.message}}></p>
              </FormError>
            )}
            {/* "handleSubmit" will validate your FormInputs before invoking "onSubmit" */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInput
                label="First Name"
                {...register('firstName', { required: true })}
                placeholder="Enter your first name"
                errorMessage={errors.firstName && REQUIRED_FIELD}
              />
              <FormInput
                label="Last Name"
                {...register('lastName', { required: true })}
                placeholder="Enter your last name"
                errorMessage={errors.lastName && REQUIRED_FIELD}
              />
              <FormInput
                label="Email"
                type="text"
                placeholder="Enter your email address"
                {...register('email', {
                  required: true,
                  pattern: EMAIL_FIELD_PATTERN,
                })}
                errorMessage={
                  errors.email &&
                  (errors.email.type === 'required'
                    ? REQUIRED_FIELD
                    : EMAIL_ERROR_MESSAGE)
                }
              />
              <FormInput
                label="Phone number"
                {...register('phone', { required: true })}
                placeholder="Enter your phone number"
                errorMessage={errors.phone && REQUIRED_FIELD}
              />
              <FormInput
                label="Company"
                {...register('company', { required: true })}
                placeholder="Company"
                errorMessage={errors.company && REQUIRED_FIELD}
              />
              <FormInput
                label="Company Address"
                {...register('companyAddress', { required: true })}
                placeholder="Company Address"
                errorMessage={errors.companyAddress && REQUIRED_FIELD}
              />
              <FormInput
                label="Company Website"
                {...register('companyUrl', { required: true })}
                placeholder="Company Website"
                errorMessage={errors.companyUrl && REQUIRED_FIELD}
              />
              <FormSelect
                {...register('userRole', { required: true })}
                aria-labelledby="userRoleLabel"
                label="Select your role"
                placeholder="Select your role"
                defaultValue=""
                errorMessage={errors.userRole && REQUIRED_FIELD}
                className={cn({ 'text-mono-600': watch('userRole') })}
              >
                <>
                  {CREATE_ACCOUNT_USER_ROLES_TRADE.map((role) => (
                    <option
                      key={encodeURI(role)}
                      value={encodeURI(role)}
                      aria-labelledby="userRoleLabel"
                    >
                      {role}
                    </option>
                  ))}
                </>
              </FormSelect>
              <FormInput
                label="Product Interested"
                isOptional
                {...register('productNote')}
                placeholder="Add product codes you want to quote"
                errorMessage={errors.productNote && REQUIRED_FIELD}
              />
              <FormInput
                label="Password"
                {...register('passwordOne', { required: true, minLength: 6 })}
                type="password"
                placeholder="Create a password"
                errorMessage={
                  errors.passwordOne &&
                  (errors.passwordOne.type === 'required'
                    ? REQUIRED_FIELD
                    : 'This password is too weak, passwords should be at least 6 characters.')
                }
              />
              <FormInput
                {...register('passwordTwo', {
                  required: true,
                  validate: (value) => value === watch('passwordOne'),
                })}
                label="Confirm Password"
                placeholder="Re-enter password"
                type="password"
                errorMessage={
                  errors.passwordTwo &&
                  (errors.passwordTwo.type === 'required'
                    ? REQUIRED_FIELD
                    : 'The passwords must match')
                }
              />
              <FormUpload
                {...register('documents', {
                  required: true,
                  onChange: (e) => {
                    setValue(
                      'documents',
                      [
                        ...(getValues('documents') || []),
                        ...e.target.files,
                      ].filter(
                        (value, index, self) =>
                          index ===
                          self.findIndex((t) => t.name === value.name),
                      ),
                    );
                  },
                  validate: {
                    maxFiles: (value) => {
                      const files = [...value];

                      if (files.length > 5) {
                        return false;
                      }
                      return true;
                    },
                    type: (value) => {
                      const files = [...value];

                      const fileTypes = files.map((file) => file.type);

                      if (
                        fileTypes.some((type) => !allowedTypes.includes(type))
                      ) {
                        setValue(
                          'documents',
                          files.filter((file) =>
                            allowedTypes.includes(file.type),
                          ),
                        );
                        return false;
                      }

                      return true;
                    },
                    size: (value) => {
                      const files = [...value];

                      if (files.some((file) => bytesToMB(file.size) > 10)) {
                        return false;
                      }

                      return true;
                    },
                  },
                })}
                label="Document Upload"
                uploadMessage="Please upload your valid EIN and/or Resale Certificates"
                type="file"
                multiple
                accept=".pdf, image/jpeg, image/png"
                errorMessage={
                  errors.documents
                    ? errors.documents.type === 'maxFiles'
                      ? [...watch('documents')]?.length > 5
                        ? getFileErrorMessage(errors.documents.type)
                        : null
                      : getFileErrorMessage(errors.documents.type)
                    : null
                }
              />
              {watch('documents')?.length ? (
                <div>
                  {[...watch('documents')].map((file, index) => (
                    <div
                      key={index}
                      className="text-mono-500 block break-all text-left mb-1 last:mb-0"
                    >
                      {file.name}{' '}
                      <button
                        aria-label="Close"
                        className={cn('btn-icon', 'opacity-50')}
                        style={{
                          backgroundImage: `url('/images/icons/icon-close.svg')`,
                        }}
                        onClick={(e) => {
                          e.preventDefault();

                          const currentFiles = [...getValues('documents')];
                          const newFiles = currentFiles.filter(
                            (currentFile) => currentFile.name !== file.name,
                          );

                          setValue('documents', newFiles);
                          trigger('documents');
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : null}
              {/* <FormHoneypot register={register} /> */}

              <div className="my-6 flex gap-2 items-start text-mono-500">
                <input
                  type="checkbox"
                  id="terms"
                  checked={isUStradeCompany ? true : false}
                  onChange={(e) => setIsUStradeCompany(e.target.checked)}
                  className={`${skuStyles.checkbox} mt-[0.125rem]`}
                />
                <label htmlFor="terms" className="type-sans-130 text-mono-500">
                  I'm applying for trade for my company in the US
                </label>
              </div>

              {showUSTradeError && (
                <div className="type-sans-130 text-mono-500 text-pink bg-lightPink border border-pink p-4 flex items-center justify-start">
                  <img
                    src="/images/icons/exclamation.png"
                    className="mr-2 w-6"
                  />
                  Only US based businesses are eligible for this program
                </div>
              )}

              <Button
                aria-label="Submit"
                type="submit"
                className={cn(
                  'btn-default btn-black btn-lg mt-4 btn-interaction',
                  {
                    'btn-black-disabled': !formState.isValid,
                  },
                  { 'is-loading': isSubmitting },
                )}
                disabled={isSubmitting}
              >
                <>
                  <span>Create Account</span>
                  <span>
                    <img
                      src={Spinner}
                      alt="Loading"
                      width="16px"
                      height="16px"
                      className="ml-6 absolute left-0 top-0"
                    />
                    Loading
                  </span>
                </>
              </Button>
            </form>
            <p className="type-sans-030 mt-6">
              Already have a Trade Account?&nbsp;
              <Link
                to="/account/login"
                styled={false}
                className="inline-link"
                aria-label="Login"
              >
                Login
              </Link>
            </p>
          </div>
          <div className="col-12 md:col-6 md:offset-1 xl:col-6">
            {_rawFirstContentModule && (
              <div className="mt-0 md:mt-40 -mx-6 md:mx-0">
                <RichTextContentBox data={_rawFirstContentModule} />
              </div>
            )}
            {secondContentModule && (
              <div className="mt-20 mb-20 md:mb-0 -mx-6 md:mx-0">
                <FeaturesModule data={secondContentModule} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(TradePageTemplate);
